export const ALL_STORYBLOK_LANGUAGES = ['default', 'de']
export default ALL_STORYBLOK_LANGUAGES

export enum LANGUAGE {
  EN = 'en',
  DE = 'de',
}

// Note: The actual default language will be determined at runtime
export const defaultLanguage = LANGUAGE.EN

export const INTERNAL_LANGUAGES: LANGUAGE[] = (() =>
  ALL_STORYBLOK_LANGUAGES.map(lang => {
    const language = lang === 'default' ? defaultLanguage.toUpperCase() : lang.toUpperCase()
    return LANGUAGE[language]
  }))()

export const langLabelMap = {
  [LANGUAGE.EN]: 'English',
  [LANGUAGE.DE]: 'Deutsch',
}

// This will be used to determine the initial language
export let runtimeDefaultLanguage = defaultLanguage

export const setRuntimeDefaultLanguage = (lang: LANGUAGE) => {
  runtimeDefaultLanguage = lang
}
