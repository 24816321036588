import { graphql, useStaticQuery, navigate } from 'gatsby'
import * as React from 'react'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { SbLink, TranslatedSlug } from '../../../types'
import { I18nContextValue, LinkList, TranslatedLinkList, Translation, TranslationKey } from './interfaces'
import { getTranslatedSlugForPage, stripOutHomeForRootPath } from '../../utils/translatedSlugs'
import { defaultLanguage, INTERNAL_LANGUAGES, LANGUAGE, runtimeDefaultLanguage, setRuntimeDefaultLanguage } from '../../../../config/storyblok/languages'
import { formatPath } from '../../utils/internalLinks'
import { detectUserLanguage, saveLanguagePreference } from '../../utils/languageDetection'

const I18nQuery = graphql`
  {
    datasource: allStoryblokDatasourceEntry(filter: { data_source: { eq: "strings" } }) {
      nodes {
        value
        name
        data_source_dimension
        dimension_value
      }
    }
    links: allStoryblokLink {
      nodes {
        slug
        uuid
        alternates {
          lang
          name
          path
        }
      }
    }
  }
`

export const I18nContext = createContext<I18nContextValue>({
  currentLanguage: defaultLanguage,
  translations: {},
  allLanguages: INTERNAL_LANGUAGES,
  translatedSlugsForPage: null,
  defaultSlug: '',
  getTranslatedUrlFromLink: () => '',
  getTranslatedUrlFromBlok: () => '',
  changeLanguage: () => undefined,
})

export const I18nProvider = ({
  pageLanguage,
  translatedSlugs,
  children,
  defaultSlug,
}: {
  pageLanguage: string
  children: JSX.Element
  translatedSlugs?: TranslatedSlug[]
  defaultSlug: string
}) => {
  const allLanguages: LANGUAGE[] = INTERNAL_LANGUAGES
  const queryResult = useStaticQuery(I18nQuery)
  const [currentLanguage, setCurrentLanguage] = useState(
    pageLanguage === 'default' ? detectUserLanguage() : pageLanguage
  )

  // Handle initial language detection and routing
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const detectedLanguage = detectUserLanguage()
      setRuntimeDefaultLanguage(detectedLanguage)

      if (detectedLanguage !== pageLanguage && pageLanguage === 'default') {
        const translatedPath = translatedSlugs?.find(slug => slug.lang === detectedLanguage)?.path
        if (translatedPath) {
          const isHomePage = !translatedPath || translatedPath === 'home'
          const newPath = isHomePage ? `/${detectedLanguage}` : `/${detectedLanguage}/${translatedPath}`
          navigate(newPath, { replace: true })
        }
      }
    }
  }, [])

  // Function to change language
  const changeLanguage = useCallback((newLanguage: LANGUAGE) => {
    saveLanguagePreference(newLanguage)
    setRuntimeDefaultLanguage(newLanguage)
    setCurrentLanguage(newLanguage)

    // If switching to English and we're not on the default route
    if (newLanguage === LANGUAGE.EN) {
      // Get the default slug without language prefix
      const defaultPath = stripOutHomeForRootPath(defaultSlug)
      // Handle home page and empty paths
      const newPath = !defaultPath || defaultPath === 'home' || defaultPath === '/' ? '/' : `${defaultPath}`
      console.log('Switching to English path:', newPath)
      navigate(newPath, { replace: true })
      return
    }

    // For other languages (German), find the translated path
    const translatedPath = translatedSlugs?.find(slug => slug.lang === newLanguage)?.path
    if (translatedPath) {
      // Handle home page and empty paths
      const isHomePage = !translatedPath || translatedPath === 'home' || translatedPath === '/'
      const newPath = isHomePage ? `/${newLanguage}` : `/${newLanguage}/${translatedPath.replace(/^\/+/, '')}`
      console.log('Switching to translated path:', newPath)
      navigate(newPath, { replace: true })
    } else {
      console.log('No translation found for:', newLanguage)
      // Fallback to language root if no translation found
      navigate(`/${newLanguage}`, { replace: true })
    }
  }, [translatedSlugs, defaultSlug, pageLanguage])

  const { datasource, links } = queryResult as {
    links: LinkList
    datasource: any
  }

  const translatedSlugsForPage = useMemo<Record<LANGUAGE, string>>(() => {
    let translatedPathsMap = {}

    translatedPathsMap[defaultLanguage] = stripOutHomeForRootPath(defaultSlug)

    translatedSlugs?.forEach(slug => {
      translatedPathsMap[slug.lang] = getTranslatedSlugForPage(slug.lang, translatedSlugs)
    })

    return translatedPathsMap as Record<LANGUAGE, string>
  }, [translatedSlugs])

  // get translations from storyblok data source
  const translations = useMemo(() => {
    const mappedTranslations: Record<LANGUAGE, Translation> | {} = {}

    if (datasource) {
      datasource?.nodes?.forEach(source => {
        let key = source.data_source_dimension || defaultLanguage
        let value = source.dimension_value || source.value

        if (!mappedTranslations[key]) {
          mappedTranslations[key] = {}
        }
        mappedTranslations[key][source.name] = value
      })
    }
    return mappedTranslations
  }, [datasource])

  const translatedLinks = useMemo<TranslatedLinkList>(() => {
    const mappedLinks: TranslatedLinkList = {}

    if (links) {
      links?.nodes.forEach(source => {
        let key = source.uuid

        mappedLinks[key] = {
          defaultSlug: source.slug,
          translatedSlugs: source.alternates,
        }
      })
    }

    return mappedLinks
  }, [links])

  const getTranslatedUrlFromBlok = useCallback((fullSlug: string, translatedSlugs: TranslatedSlug[]) => {
    // Get the translated path for the current language
    const effectiveLanguage = currentLanguage === 'default' ? defaultLanguage : currentLanguage

    const translatedPath = effectiveLanguage === defaultLanguage
      ? formatPath(fullSlug)
      : getTranslatedSlugForPage(effectiveLanguage, translatedSlugs)

    // If no translation found, use default slug
    const finalPath = translatedPath || formatPath(fullSlug)

    // Remove any existing language prefix and clean up the path
    const cleanPath = finalPath.replace(/^\/(de|en)\//, '').replace(/^\/+/, '')

    // Add language prefix for non-default languages
    const pathWithPrefix = effectiveLanguage === defaultLanguage
      ? `/${cleanPath}`
      : `/${effectiveLanguage}/${cleanPath}`

    // Handle home page special case
    if (pathWithPrefix === '/home') return '/'
    if (pathWithPrefix === `/${effectiveLanguage}/home`) return `/${effectiveLanguage}`

    return pathWithPrefix
  }, [currentLanguage])

  const getTranslatedUrlFromLink = useCallback(
    (link: SbLink) => {
      switch (link?.linktype) {
        case 'url': {
          return link?.url
        }
        case 'asset': {
          return formatPath(link?.cached_url)
        }
        case 'email': {
          return `mailto:${link?.cached_url}`
        }
        case 'story':
        default: {
          const currentLinkItem = translatedLinks[link?.id]

          if (!currentLinkItem) return

          const effectiveLanguage = currentLanguage === 'default' ? defaultLanguage : currentLanguage

          // Get the translated path for the current language
          const translatedPath = effectiveLanguage === defaultLanguage
            ? formatPath(currentLinkItem.defaultSlug)
            : getTranslatedSlugForPage(effectiveLanguage, currentLinkItem.translatedSlugs)

          // If no translation found, use default slug
          const finalPath = translatedPath || formatPath(currentLinkItem.defaultSlug)

          // Remove any existing language prefix and clean up the path
          const cleanPath = finalPath.replace(/^\/(de|en)\//, '').replace(/^\/+/, '')

          // Add language prefix for non-default languages
          const pathWithPrefix = effectiveLanguage === defaultLanguage
            ? `/${cleanPath}`
            : `/${effectiveLanguage}/${cleanPath}`

          // Handle home page special case
          if (pathWithPrefix === '/home') return '/'
          if (pathWithPrefix === `/${effectiveLanguage}/home`) return `/${effectiveLanguage}`

          return link?.anchor ? `${pathWithPrefix}#${link?.anchor}` : pathWithPrefix
        }
      }
    },
    [currentLanguage, translatedLinks],
  )


  return (
    <I18nContext.Provider
      value={{
        allLanguages,
        currentLanguage: currentLanguage === 'default' ? defaultLanguage : currentLanguage,
        defaultSlug,
        getTranslatedUrlFromBlok,
        getTranslatedUrlFromLink,
        translatedSlugsForPage,
        translations,
        changeLanguage,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}

export const useI18n = () => React.useContext(I18nContext)

export const useCurrentTranslations = (): Translation | null => {
  const { currentLanguage, translations } = useI18n()

  return translations[currentLanguage] || null
}

export const useTranslationString = (key: string) => {
  const translations = useCurrentTranslations()

  return translations?.[key] || key
}

export const useCurrentLanguageKey = () => {
  const { currentLanguage } = useI18n()

  return currentLanguage
}

export const useTranslation = () => {
  const translations = useCurrentTranslations()

  const t = React.useCallback(
    (key: TranslationKey | string) => (translations ? translations[key] || key : key),
    [translations],
  )

  return t
}

export const useTranslatedLinksFromBlok = () => {
  const { getTranslatedUrlFromBlok } = useI18n()

  return getTranslatedUrlFromBlok
}

